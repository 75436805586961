export enum Summary {
  win = 'win',
  draw = 'draw',
  lose = 'lose',
  start = 'start',
}

export type GameContextProps = {
  summary: keyof typeof Summary | undefined;
  handleSetSummary: (summary: keyof typeof Summary) => void;
  disabled: boolean;
  handleSetDisabled: (disabled: boolean) => void;
};
