import React from 'react';

import { SingleGame } from '../../components/singleGame/SingleGame';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

import { Styled } from './Game.styles';

export const Game = () => {
  return (
    <Styled.Wrapper>
      <SingleGame />
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
    </Styled.Wrapper>
  );
};
