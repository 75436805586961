import { Colors, PaletteColors } from './colors.types';

const grey: PaletteColors = {
  100: '#F2F3F3',
  150: '#EAF0F4',
  200: '#DCE3ED',
  300: '#D2D8E2',
  400: '#CBCCCC',
  500: '#C4D0D5',
  600: '#6A7687',
  700: '#707486',
  800: '#707070',
  900: '#4E555E',
};

const cyan: PaletteColors = {
  100: '#C5E0E0',
  200: '#525F7F',
  400: '#1C5A7D',
  500: '#1A5373',
  600: '#134359',
  700: '#0F3144',
  900: '#072E40',
};

const orange: PaletteColors = {
  100: '#F9AF55',
  400: '#FCB614',
  500: '#FA961E',
  900: '#F7692A',
};

const yellow: PaletteColors = {
  100: '#FEEDC4',
  500: '#FDC84F',
  900: '#FCB614',
};

const green: PaletteColors = {
  100: '#60C797',
  300: '#50A1A3',
  500: '#2BB574',
  700: '#158285',
  800: '#0E7479',
};

const red: PaletteColors = {
  100: '#FFCCDD2',
  200: '#EF9A9A',
  300: '#E57373',
  400: '#EF5350',
  500: '#F44336',
  600: '#E53935',
  700: '#D32F2F',
  800: '#C62E07',
  900: '#B71C1C',
};

const genericColors: Colors = {
  white: '#ffffff',
  black: '#000000',
  blackLowOpacity: 'rgba(0, 0, 0, 0.2)',
  blackLowerOpacity: 'rgba(0, 0, 0, 0.05)',
  transparent: 'rgba(0, 0, 0, 0)',
  danger: '#C62E07',
  darkerWhite: '#E7E6E6',
  mediumWhite: '#E7ECF4',
  darkWhite: '#F3F6F9',
  disabled: '#789DB2',
  primary: cyan[400],
  primaryHover: cyan[500],
  primaryHoverRipple: grey[100],
  warning: orange[900],
  info: green[500],
  mainText: grey[900],
  success: green[500],
  mainBackground: grey[100],
  lowerFooterBackground: grey[150],
  veryLightGreyText: grey[600],
  cyanText: cyan[200],
  header: cyan[600],
  gray: '#808080',
  silverChalice: '#AAAAAA',
};

export const colors: Colors = {
  ...genericColors,
  grey,
  green,
  yellow,
  orange,
  cyan,
  red,
};
