import React from 'react';

import { WinSummary } from 'components/winSummary/WinSummary';

import { Styled } from './Summary.styles';

export const Summary = () => {
  return (
    <Styled.Wrapper>
      <WinSummary />
    </Styled.Wrapper>
  );
};
