import styled from 'styled-components';

import img from 'assets/images/background-r.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 100px;
  min-height: 730px;
  position: relative;
  background-position: center center;
  max-width: 800px;
  background-size: contain;
`;

export const Styled = {
  Wrapper,
};
