import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Circle } from '../../assets/images/circle.svg';
import { ReactComponent as Select } from '../../assets/images/select.svg';
import { ReactComponent as WinQrCode } from '../../assets/images/win-qr-code.svg';

import { Styled } from './SelectGame.styles';

export const SelectGame = () => {
  const navigate = useNavigate();
  const handleSelect = () => {
    navigate('/start-game');
  };

  const handleClick = () => {
    window.location.assign('https://google.pl');
  };

  return (
    <Styled.Wrapper>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.Text>Wybierz grę!</Styled.Text>
      <Styled.Row>
        <Styled.BigIcon onClick={handleClick}>
          <Select />
          <Styled.Description>Dopasowanie</Styled.Description>
        </Styled.BigIcon>
        <Styled.BigIcon onClick={handleSelect}>
          <Circle />
          <Styled.Description>Kółko i Krzyżyk</Styled.Description>
        </Styled.BigIcon>
      </Styled.Row>
      <Styled.QrWrapper>
        <Styled.Icon>
          <WinQrCode />
        </Styled.Icon>
        <Styled.Link>pwc.pl/kariera</Styled.Link>
      </Styled.QrWrapper>
    </Styled.Wrapper>
  );
};
