import React from 'react';

import { Square } from 'components/square/Square';
import { useGame } from 'hooks/useGame/useGame';

import { BoardProps } from './Board.types';
import { Styled } from './Board.styles';

export const Board: React.FC<BoardProps> = ({ squares, onClick }) => {
  const { disabled } = useGame();

  const renderSquare = (i: number) => {
    return <Square disabled={disabled} i={i} value={squares[i]} onClick={() => onClick(i)}></Square>;
  };
  return (
    <div>
      <div className="board-row board-row-first">
        <Styled.Box>{renderSquare(0)}</Styled.Box>
        <Styled.Box>{renderSquare(1)}</Styled.Box>
        <Styled.Box>{renderSquare(2)}</Styled.Box>
        <Styled.Box>{renderSquare(3)}</Styled.Box>
        <Styled.Box>{renderSquare(4)}</Styled.Box>
      </div>
      <div className="board-row board-row-second">
        <Styled.Box>{renderSquare(5)}</Styled.Box>
        <Styled.Box>{renderSquare(6)}</Styled.Box>
        <Styled.Box>{renderSquare(7)}</Styled.Box>
        <Styled.Box>{renderSquare(8)}</Styled.Box>
        <Styled.Box>{renderSquare(9)}</Styled.Box>
      </div>
      <div className="board-row board-row-third">
        <Styled.Box>{renderSquare(10)}</Styled.Box>
        <Styled.Box>{renderSquare(11)}</Styled.Box>
        <Styled.Box>{renderSquare(12)}</Styled.Box>
        <Styled.Box>{renderSquare(13)}</Styled.Box>
        <Styled.Box>{renderSquare(14)}</Styled.Box>
      </div>
      <div className="board-row board-row-fourth">
        <Styled.Box>{renderSquare(15)}</Styled.Box>
        <Styled.Box>{renderSquare(16)}</Styled.Box>
        <Styled.Box>{renderSquare(17)}</Styled.Box>
        <Styled.Box>{renderSquare(18)}</Styled.Box>
        <Styled.Box>{renderSquare(19)}</Styled.Box>
      </div>
      <div className="board-row board-row-five">
        <Styled.Box>{renderSquare(20)}</Styled.Box>
        <Styled.Box>{renderSquare(21)}</Styled.Box>
        <Styled.Box>{renderSquare(22)}</Styled.Box>
        <Styled.Box>{renderSquare(23)}</Styled.Box>
        <Styled.Box>{renderSquare(24)}</Styled.Box>
      </div>
    </div>
  );
};
