import { useContext } from 'react';

import { GameContext } from 'context/game/GameContext';

export const useGame = () => {
  const game = useContext(GameContext);

  if (game === undefined) {
    throw new Error('GameContext is unavailable, make sure you are using GameProvider');
  }

  return {
    game: game.summary,
    handleSetSummary: game.handleSetSummary,
    disabled: game.disabled,
    setDisabled: game.handleSetDisabled,
  };
};
