import React from 'react';

import { SquareProps } from './Square.types';

export const Square: React.FC<SquareProps> = ({ value, onClick, disabled }) => {
  return (
    <button disabled={disabled} className="square" onClick={onClick}>
      {value}
    </button>
  );
};
