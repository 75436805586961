import styled from 'styled-components';

import img from 'assets/images/background-r.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 730px;
  position: relative;
  background-position: center center;
  max-width: 800px;
  background-size: contain;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border: 1px solid #727272;
  font-size: 21px;
  text-align: center;
  color: #727272;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  background: transparent;
  padding-top: 5px;
  position: relative;
  margin-bottom: 100px;

  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 100px;
  svg {
    width: 80px;
    height: 80px;
  }
`;

export const Styled = {
  Wrapper,
  Button,
  Logo,
};
