import React from 'react';

import { AppRoutes } from 'routing/AppRoutes';
import './App.css';

export const App = () => {
  return (
    <div className="app">
      <main className="app__main">
        <AppRoutes />
      </main>
    </div>
  );
};
