import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGame } from 'hooks/useGame/useGame';
import { ReactComponent as WinQrCode } from '../../assets/images/win-qr-code.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { Summary } from 'context/game/GameContext.types';

import { Styled } from './WinSummary.styles';

export const WinSummary = () => {
  const { game, handleSetSummary } = useGame();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
    handleSetSummary('start');
  };

  return (
    <>
      {game && game === Summary.win ? (
        <div>
          <Styled.Text>Wygrana:</Styled.Text>
          <Styled.Description>
            Kto jak nie Ty! <br /> Teraz sprawdź się w rekrutacji <br />
            do PwC!
          </Styled.Description>
        </div>
      ) : null}
      {game && game === Summary.lose ? (
        <div>
          <Styled.Text>Przegrana:</Styled.Text>
          <Styled.Description>
            Prawie się udało… <br /> Rekrutacja do PwC na pewno <br />
            pójdzie Ci lepiej!
          </Styled.Description>
        </div>
      ) : null}
      {game && game === Summary.draw ? (
        <div>
          <Styled.Text>Remis:</Styled.Text>
          <Styled.Description>
            O włos od wygranej! <br />A bliżej są tylko oferty pracy <br /> w PwC. Aplikuj!
          </Styled.Description>
        </div>
      ) : null}
      <Styled.Icon>
        <WinQrCode />
      </Styled.Icon>
      <Styled.Link>pwc.pl/kariera</Styled.Link>
      <Styled.Button onClick={handleClick}>Dalej</Styled.Button>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
    </>
  );
};
