import React, { useEffect, useState } from 'react';

import { Summary } from 'context/game/GameContext.types';
import { GameContext } from 'context/game/GameContext';

import { GameProviderProps } from './GameProvider.types';

export const GameProvider = (props: GameProviderProps) => {
  const [summary, setSummary] = useState<keyof typeof Summary>();
  const [disabled, setDisabled] = useState(false);
  const handleSetSummary = (summary: keyof typeof Summary) => {
    setSummary(summary);
  };

  const handleSetDisabled = (disabled: boolean) => {
    setDisabled(disabled);
  };

  return (
    <GameContext.Provider value={{ summary, disabled, handleSetSummary, handleSetDisabled }}>
      {props.children}
    </GameContext.Provider>
  );
};
