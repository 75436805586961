import { createBreakpoints } from './breakpoints';
import { Theme } from './theme.types';

export const createTheme = (theme: Theme) => {
  return {
    ...theme,
    breakpoints: createBreakpoints(theme.breakpoints),
  };
};

export type StyledTheme = ReturnType<typeof createTheme>;
