import styled from 'styled-components';

const Box = styled.div`
  display: flex;

  button {
    background: #fff;
    border: 1px solid #fff;
    font-size: 40px;
    line-height: initial;
    height: 50px;
    width: 50px;
    text-align: center;
    font-family: 'HelveticaNeueLT Pro 55 Roman';
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;

    &:disabled {
      color: #000;
    }
  }
`;

export const Styled = {
  Box,
};
