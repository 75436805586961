import styled from 'styled-components';

import img from 'assets/images/background-r.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 730px;
  position: relative;
  background-position: center center;
  max-width: 800px;
  background-size: contain;
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 100px;
  svg {
    width: 80px;
    height: 80px;
  }
`;

export const Styled = {
  Wrapper,
  Logo,
};
