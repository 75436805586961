import styled from 'styled-components';

const Text = styled.p`
  color: #000000;
  font-size: 47px;
  text-align: center;
  font-family: 'ITC Charter Com';
  margin-bottom: 15px;
`;

const Description = styled.p`
  color: #000000;
  font-size: 19px;
  text-align: center;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  line-height: 26px;
  margin-bottom: 36px;
`;

const Link = styled.p`
  color: #000000;
  font-size: 19px;
  text-align: center;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  line-height: 26px;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  svg {
    width: 110px;
    height: 110px;
  }
`;

const Button = styled.button`
  width: 120px;
  height: 50px;
  background: #464646;
  color: #ffffff;
  font-size: 22px;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  line-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
  position: absolute;
  bottom: 100px;

  svg {
    width: 80px;
    height: 80px;
  }
`;

export const Styled = {
  Text,
  Description,
  Icon,
  Link,
  Button,
  Logo,
};
