import styled from 'styled-components';

import img from 'assets/images/start-background.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 730px;
  position: relative;
  padding-top: 180px;
  background-position: center center;
  max-width: 800px;
  background-size: contain;
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 40px;
  svg {
    width: 80px;
    height: 80px;
  }
`;

const Link = styled.p`
  color: #000000;
  font-size: 19px;
  text-align: center;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  line-height: 26px;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  svg {
    width: 110px;
    height: 110px;
  }
`;

const Description = styled.p`
  color: #000000;
  font-size: 18px;
  text-align: center;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  line-height: 26px;
`;

const QrWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
`;

const BigIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  color: #000000;
  font-size: 47px;
  text-align: center;
  font-family: 'ITC Charter Com';
  margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-around;
  margin-top: 20px;
`;

export const Styled = {
  Wrapper,
  Logo,
  Link,
  Icon,
  Text,
  Row,
  BigIcon,
  QrWrapper,
  Description,
};
