import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Home } from 'app/home/Home';
import { Game } from 'app/game/Game';
import { Summary } from 'app/summary/Summary';
import { SelectGame } from 'app/selectGame/SelectGame';

import { AppRoute } from './AppRoute.enum';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={AppRoute.home} element={<SelectGame />} />
      <Route path={AppRoute.startGame} element={<Home />} />
      <Route path={AppRoute.game} element={<Game />} />
      <Route path={AppRoute.summary} element={<Summary />} />
    </Routes>
  );
};
