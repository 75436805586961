import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';

import { Styled } from './Home.styles';

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // const element = document.getElementById('root');

    // if (element) {
    //   element
    //     .requestFullscreen()
    //     .then(() => {})
    //     .catch((error) => {});
    // }
    navigate('/game');
  };

  return (
    <Styled.Wrapper>
      <Styled.Button onClick={handleClick}>Start</Styled.Button>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
    </Styled.Wrapper>
  );
};
