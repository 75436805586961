import React from 'react';

import { RouterProvider } from './router/RouterProvider';
import { ThemeProvider } from './theme/ThemeProvider';
import { AppProvidersProps } from './AppProviders.types';
import { GameProvider } from './game/GameProvider';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <RouterProvider>
    <ThemeProvider>
      <GameProvider>{children}</GameProvider>
    </ThemeProvider>
  </RouterProvider>
);
