import { breakpoints } from './breakpoints';
import { Theme } from './theme.types';
import { colors } from './colors';

export const defaultTheme: Theme = {
  colors,
  shadows: {
    blackShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    mediumBlackShadow: '0 8px 32ox rgba(17, 18, 20, 0.16)',
    blackShadow01: '2px -3px 2px 1px rgba(0, 0, 0, 0.1)',
    blackShadow008: 'inset 0 -2px 0 0 rgba(0, 0, 0, 0.08)',
    button: '0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);',
  },
  breakpoints,
  borderRadius: {
    xs: '2px',
    sm: '4px',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
};
