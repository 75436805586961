/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Board } from 'components/board/Board';
import { useGame } from 'hooks/useGame/useGame';

import { winLines } from './Lines';

export const SingleGame: React.FC = () => {
  const navigate = useNavigate();

  const [stepHistory, setStepHistory] = useState([{ squares: Array(25).fill(null) }]);
  const [stepNumber, setStepNumber] = useState(0);
  const [xIsNext, setXIsNext] = useState(true);
  const [winner, setWinner] = useState('');
  const { setDisabled, handleSetSummary } = useGame();

  const handleReset = () => {
    setStepHistory([{ squares: Array(25).fill(null) }]);
    setStepNumber(0);
    setXIsNext(true);
    setDisabled(false);
  };

  useEffect(() => {
    if (!xIsNext && stepNumber !== 25) {
      setDisabled(true);
      const test = stepHistory[stepHistory.length - 1].squares.map((item, index) => {
        if (item === null) {
          return index;
        }
      });
      const array = test.filter((e) => e);
      const randomValue = array.filter((e) => e)[Math.floor(Math.random() * array.length)];
      setTimeout(() => {
        handleClick(randomValue);
      }, 300);
    }
  }, [xIsNext]);

  useEffect(() => {
    if (winner) {
      winner === 'X' ? handleSetSummary('win') : handleSetSummary('lose');
      if (winner === 'draw') handleSetSummary('draw');
      navigate('/summary');
    }
  }, [winner]);

  useEffect(() => {
    handleReset();
  }, [navigate]);

  const calculateWinner = (squares: any[]) => {
    // const lines = [
    //   [0, 1, 2],
    //   [3, 4, 5],
    //   [6, 7, 8],
    //   [0, 3, 6],
    //   [1, 4, 7],
    //   [2, 5, 8],
    //   [0, 4, 8],
    //   [2, 4, 6],
    // ];

    const lines = winLines;

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c, d, e] = lines[i];

      if (
        squares[a] &&
        squares[a] === squares[b] &&
        squares[a] === squares[c] &&
        squares[a] === squares[d] &&
        squares[a] === squares[e]
      ) {
        return squares[a];
      }
    }
    return null;
  };

  const handleClick = (i: any) => {
    const history = stepHistory.slice(0, stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();

    setStepHistory(
      history.concat([
        {
          squares: squares,
        },
      ]),
    );

    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    squares[i] = xIsNext ? 'X' : 'O';

    setStepNumber(history.length);
    setXIsNext(!xIsNext);
    setDisabled(false);

    if (!squares.filter((el) => el === null).length) {
      setWinner('draw');
      return;
    }
  };

  const renderData = () => {
    const history = stepHistory;
    const current = history[stepNumber];
    const winner = calculateWinner(current.squares);

    let status;
    if (winner) {
      setWinner(winner);
      status = '';
    } else {
      status = xIsNext ? 'Twój ruch' : '';
    }

    return (
      <div className="game">
        <div className="game-info">
          <p>{status}</p>
        </div>
        <div className="game-board">
          <Board squares={current.squares} onClick={(e) => handleClick(e)}></Board>
        </div>
      </div>
    );
  };

  return !winner ? renderData() : <></>;
};
