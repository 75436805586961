import React from 'react';
import { ThemeProvider as StyledThemeProvider, StyleSheetManager } from 'styled-components';
import { GlobalStyles } from 'globalStyles';

import { createTheme } from 'assets/themes/createTheme';
import { defaultTheme } from 'assets/themes/defaultTheme';

import { ThemeProviderProps } from './ThemeProvider.types';

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <StyledThemeProvider theme={createTheme(defaultTheme)}>
      <StyleSheetManager>
        <>
          <GlobalStyles />
          {children}
        </>
      </StyleSheetManager>
    </StyledThemeProvider>
  );
};
