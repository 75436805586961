import { createGlobalStyle } from 'styled-components';
import Frutiger from 'src/assets/fonts/Frutiger/FrutigerLTArabic-55Roman.ttf';

export const GlobalStyles = createGlobalStyle`
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
  }  
`;
